.sign-up-container {
  display: flex;
  flex-direction: column;
  width: 380px;

  h2 {
    font-size: 27px;
    margin: 10px 0;
  }
}
