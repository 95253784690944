.banner-container {
  width: 100%;
  background-color: rgba(126, 46, 46, 0.651);
  padding: 10px;

  .banner-message {
    color: white;
    font-size: 20px;
    text-align: center;
  }
}
