.accordion {
  font-size: 15px;
  font-family: "Vollkorn SC", serif;
  // cursor: pointer;
}

.accordion__button {
  font-family: "Vollkorn SC", serif;

  border: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: inherit;
  background: transparent;
  border-bottom: black 1px solid;
  // border-top: black 1px solid;
}

.accordion__button span {
  // height: 30px;
  // width: 30px;
  // border-radius: 50%;
  color: rgb(0, 0, 0);
  // background: #000;
  // font-size: 12px;
  cursor: pointer;
}

.accordion__button:focus {
  outline: none;
}

.accordion__content {
  // padding: 0 20px;
  opacity: 0;
  transition: all 0.5s;
  height: 0;
  width: auto;
  margin: 0;
  // margin-top: -10px;
  border: 1px solid #f1f1f1;
  border-top: 0;
}

.accordion__content.active {
  transition: all 0.5s;

  height: auto;
  opacity: 1;
  padding: 20px;
}
