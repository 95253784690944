.product-materials-section-container {
  height: 110vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  padding: 75px 125px;
  background: rgba(36, 36, 36, 0.7);
  .left-side {
    width: 50%;
    height: 100%;
    .left-side-image {
      //   border: 1px solid black;
      //   display: none;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: -100px;
    }
  }
  .right-side {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 100px;
    color: white;
    h1 {
      font-size: 30px;
      font-weight: lighter;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 25px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      line-height: 25px;
      font-size: larger;
    }
  }
}
