.product-showcase-container {
  padding: 100px;

  .product-showcase-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    h2,
    h3 {
      font-family: "Vollkorn SC", serif;
      opacity: 0.7;
      padding-bottom: 20px;
    }

    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 24px;
      text-decoration: underline;
    }
  }
  .product-showcase {
    display: flex;
    flex-direction: row;
  }
}
