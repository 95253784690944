.other-pieces-you-will-love-container {
  width: 100vw;
  height: 90vh;
  background: rgba(117, 116, 116, 0.877);

  display: flex;
  flex-direction: column;

  h1 {
    font-size: 30px;
    padding: 50px 0;
    text-align: center;
    text-transform: uppercase;
  }

  .product-preview-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
